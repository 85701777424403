.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #30353f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.theme-default {
  background: #282c34 !important;
}
.navigation {
  flex-direction: row;
}
.App-link {
  color: #61dafb;
}
.home {
  flex-wrap: nowrap;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.social-container {
  background: #282c34;
  padding: 25px 50px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.linkedin {
  color: #0077b5;
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: black;
}

a.github {
  color: black;
}

a.stack {
  color: #f48530;
}

a.kaggle {
  color: #1dbeff;
}

a.hackerrank {
  color: #32c665;
}

a.reddit {
  color: #fe4600;
}

.contactFormWidth {
  width: 60rem;
}
